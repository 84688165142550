import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Application from "../components/itinerary/application";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Metatags title="Itinerary application | May 19 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-19.png" url="https://cssgrid31.brett.cool" pathname="/may-19" mdxType="Metatags" />
    <Application mdxType="Application" />
    <Footer date={19} prev={true} next={true} mdxType="Footer">
      <p>{`Back in February, I used Middleman to prototype an `}<a parentName="p" {...{
          "href": "https://itinerary.brett.cool"
        }}>{`Itinerary application`}</a>{` for an upcoming trip to Japan.`}</p>
      <p>{`It proved to be very handy while we were there.`}</p>
      <p>{`Built over the course of a 3 hour flight from Perth to Melbourne, I refactored the code with React and Gatsby, and design with CSS Grid.`}</p>
      <p>{`Adding a split view design so that you could navigate by day without having to change screen was an obvious improvement, made simple thanks to React’s state lifecycle methods.`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      